import React from 'react'
import { Link } from 'react-router-dom'

const Gridimages = () => {
    return (
        <>
            <div className="page-content">

                <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url(/assets/images/banner/2.jpg)' }}>
                    <div className="overlay-main bg-black opacity-07" />
                    <div className="container ImgMar">
                        <div className="wt-bnr-inr-entry">
                            <div className="banner-title-outer">
                                <div className="banner-title-name">
                                    <h2 className="text-white">Gallery</h2>
                                </div>
                            </div>

                            <div>
                                <ul className="wt-breadcrumb breadcrumb-style-2">
                                    <li><Link to="/"><a >Home</a></Link> </li>
                                    <li>Gallery</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="section-full small-device  p-t80 p-b50 bg-gray">
                    <div className="container">

                        {/* <div className="filter-wrap p-b30 text-center">
                            <ul className="filter-navigation masonry-filter text-uppercase">
                                <li className="active"><a data-filter="*" data-hover="All" href="#">All</a></li>
                                <li><a data-filter=".cat-1" data-hover="Bathroom" href="javascript:;">Bathroom</a></li>
                                <li><a data-filter=".cat-4" data-hover="Spa" href="javascript:;">Spa</a></li>
                                <li><a data-filter=".cat-2" data-hover="Furniture" href="javascript:;">Furniture</a></li>
                                <li><a data-filter=".cat-3" data-hover="Decor" href="javascript:;">Decor</a></li>
                                <li><a data-filter=".cat-5" data-hover="Building" href="javascript:;">Building</a></li>
                                <li><a data-filter=".cat-6" data-hover="Living" href="javascript:;">Living </a></li>
                            </ul>
                        </div> */}


                        <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                            <div className="masonry-item cat-1  col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets/images/gallery/pic1.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4> Bedroom</h4>
                                            <h5> Villas</h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-2 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets/images/gallery/pic2.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Office </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets/images/gallery/pic3.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-4 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets/images/gallery/pic4.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Residential </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-5 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets/images/gallery/pic5.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas</h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-6 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets/images/gallery/pic6.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas</h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-1 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets/images/gallery/pic7.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Balcony</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-2 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets/images/gallery/pic8.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Dinning </h4>
                                            <h5>Villas</h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>                          
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\1.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\2.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\1.png.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\3.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\4.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\5.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\6.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\7.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Dinning</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\8.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\9.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\10.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\11.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\2.png.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\12.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\13.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\14.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\15.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\16.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\17.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\18.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\19.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\20.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\3.png.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\21.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\22.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\23.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\24.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\25.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\27.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\4.png.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\28.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\29.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\30.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\5.png.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\31.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\32.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\33.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\34.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\35.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Front</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\36.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Front</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\37.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\38.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\39.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\40.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\41.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Steps</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\42.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Kitchen</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\6.png.jpg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\43.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Roof</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\44.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Roof</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\45.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\46.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\47.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Temple Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\48.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Roof</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\49.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Bed Room</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                                <div className="wt-box">
                                    <div className="work-hover-grid image-container">
                                        <img src="assets\images\gallery\interiorImages\50.jpeg" alt="" />
                                        <div className="work-hover-discription">
                                            <h4>Hall</h4>
                                            <h5>Villas </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






        </>
    )
}

export default Gridimages