import React from 'react'
import { Link } from 'react-router-dom'

const ServicesPage = () => {
return (
    <>
    <div className="section-full p-t80 p-b50 bg-gray">
                <div className="container">
                    <div className="section-head text-center">
                        <div className="wt-separator-outer separator-center">
                            <div className="wt-separator">
                                <span className="text-primary text-uppercase sep-line-one ">Our Services are:</span>
                            </div>
                        </div>
                        <h2>What we do</h2>
                    </div>
                    <div className="section-content">
                        <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b30">
                                <div className="hover-box-effect  v-icon-effect">
                                    <div className="wt-box">
                                        <div className="wt-thum-bx wt-img-effect fade-in">
                                        <Link to="/grid-images"><img src="assets/images/gallery/pic4.jpg" alt="" /></Link>    
                                            <div className="wt-icon-box-sm bg-white">
                                                <span className="icon-cell text-primary"><i className="v-icon flaticon-plant" /></span>
                                            </div>
                                        </div>
                                        <div className="p-a20 bg-white">
                                            <div className="icon-content text-black">
                                            <Link to="/grid-images"><h4 className="wt-tilte m-b25" style={{textAlign:'center', marginTop:'0px'}}>Residential</h4></Link> 
                                                <p>We offer contemporary interior design for homes at reasonable prices.</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b30">
                                <div className="hover-box-effect  v-icon-effect">
                                    <div className="wt-box">
                                        <div className="wt-thum-bx wt-img-effect fade-in">
                                        <Link to="/grid-images"><img src="assets/images/gallery/pic3.jpg" alt="" /></Link>    
                                            <div className="wt-icon-box-sm bg-white">
                                                <span className="icon-cell text-primary"><i className="v-icon flaticon-window-5" /></span>
                                            </div>
                                        </div>
                                        <div className="p-a20 bg-white">
                                            <div className="icon-content text-black">
                                            <Link to="/grid-images"><h4 className="wt-tilte m-b25" style={{textAlign:'center', marginTop:'0px'}}>Flats</h4></Link>    
                                                <p>Modernised solutions for your apartments are offered to you by our highly qualified team.</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b30">
                                <div className="hover-box-effect  v-icon-effect">
                                    <div className="wt-box">
                                        <div className="wt-thum-bx wt-img-effect fade-in">
                                        <Link to="/grid-images"><img src="assets/images/gallery/pic1.jpg" alt="" /></Link>    
                                            <div className="wt-icon-box-sm bg-white">
                                                <span className="icon-cell text-primary"><i className="v-icon flaticon-sketch" /></span>
                                            </div>
                                        </div>
                                        <div className="p-a20 bg-white">
                                            <div className="icon-content text-black">
                                            <Link to="/grid-images"><h4 className="wt-tilte m-b25" style={{textAlign:'center', marginTop:'0px'}}>Villas</h4></Link>
                                                <p>For your villas, our skilled, experienced architects & designers offer solutions.</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b30">
                                <div className="hover-box-effect  v-icon-effect">
                                    <div className="wt-box">
                                        <div className="wt-thum-bx wt-img-effect fade-in">
                                        <Link to="/grid-images"><img src="assets/images/gallery/pic2.jpg" alt="" /></Link>    
                                            <div className="wt-icon-box-sm bg-white">
                                                <span className="icon-cell text-primary"><i className="v-icon flaticon-window" /></span>
                                            </div>
                                        </div>
                                        <div className="p-a20 bg-white">
                                            <div className="icon-content text-black">
                                            <Link to="/grid-images"><h4 className="wt-tilte m-b25" style={{textAlign:'center', marginTop:'0px'}}>Office</h4></Link>    
                                                <p>Our office interior designers offer outstanding ideas for giving your office a professional appearance.</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
    
    
    </>
)
}

export default ServicesPage