import React from 'react'
import WelcomeSectionSlider from '../../components/slider/WelcomeSectionSlider'
import WelcomeSectionSlider2 from '../../components/slider/WelcomeSectionSlider2'

const WelcomeSection = () => {
    return (
        <>
            <div className="section-full p-tb80 bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="index-about3 bg-gray">
                                    <div className="section-head">
                                        <div className="wt-separator-outer separator-left">
                                            <div className="wt-separator">
                                                <span className="text-primary text-uppercase sep-line-one ">Welcome to R D Interior Decorator</span>
                                            </div>
                                        </div>
                                        <h2>We Create Amazing Designs</h2>
                                    </div>
                                    {/* TITLE END */}
                                    {/* <p>Making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text,</p>
                                    <div className="wt-accordion acc-bg-white" id="accordion5">
                                        <div className="panel wt-panel">
                                            <div className="acod-head acc-actives">
                                                <h6 className="acod-title">
                                                    <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                        Architectural Design
                                                        <span className="indicator"><i className="fa" /></span>
                                                    </a>
                                                </h6>
                                            </div>
                                            <div id="collapseOne5" className="acod-body collapse in">
                                                <div className="acod-content p-tb15">Web design lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</div>
                                            </div>
                                        </div>
                                        <div className="panel wt-panel">
                                            <div className="acod-head">
                                                <h6 className="acod-title">
                                                    <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                        Interior Design
                                                        <span className="indicator"><i className="fa" /></span>
                                                    </a>
                                                </h6>
                                            </div>
                                            <div id="collapseTwo5" className="acod-body collapse">
                                                <div className="acod-content p-tb15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                            </div>
                                        </div>
                                        <div className="panel wt-panel">
                                            <div className="acod-head">
                                                <h6 className="acod-title">
                                                    <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                        Corporate Design
                                                        <span className="indicator"><i className="fa" /></span>
                                                    </a>
                                                </h6>
                                            </div>
                                            <div id="collapseThree5" className="acod-body collapse">
                                                <div className="acod-content p-tb15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                            </div>
                                        </div>
                                        <div className="panel wt-panel">
                                            <div className="acod-head acc-actives">
                                                <h6 className="acod-title">
                                                    <a data-toggle="collapse" href="#collapseFour5" data-parent="#accordion5">
                                                        Architectural Design
                                                        <span className="indicator"><i className="fa" /></span>
                                                    </a>
                                                </h6>
                                            </div>
                                            <div id="collapseFour5" className="acod-body collapse in">
                                                <div className="acod-content p-tb15">Web design lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</div>
                                            </div>
                                        </div>
                                        <div className="panel wt-panel">
                                            <div className="acod-head">
                                                <h6 className="acod-title">
                                                    <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                        Interior Design
                                                        <span className="indicator"><i className="fa" /></span>
                                                    </a>
                                                </h6>
                                            </div>
                                            <div id="collapseFive5" className="acod-body collapse">
                                                <div className="acod-content p-tb15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                            </div>
                                        </div>
                                        <div className="panel wt-panel">
                                            <div className="acod-head">
                                                <h6 className="acod-title">
                                                    <a data-toggle="collapse" href="#collapseSix5" className="collapsed" data-parent="#accordion5">
                                                        Corporate Design
                                                        <span className="indicator"><i className="fa" /></span>
                                                    </a>
                                                </h6>
                                            </div>
                                            <div id="collapseSix5" className="acod-body collapse">
                                                <div className="acod-content p-tb15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <p>R D Interior Decorator is well-known for being Hyderabad's Best Interior Designer and for decorating homes. All of the wants and necessities
                                        of interior design for your dream home and commercial space are met by our interior designers.</p>
                                    <p> You can express your preferences and desires through R D Interior Decorator in order to fit your current way of life. Modern design
                                        elements like fixed or loose furniture, modular kitchens, and contemporary style improve the aesthetics and usability of your house.
                                        To meet everyone's needs and to reflect his taste, style, and dignity, R D Interior Decorator offers a broad collection of interior design in Hyderabad.</p>
                                    <p>Experienced designers, architects, and construction specialists make up our team, and they collaborate to ensure that every project is finished to the greatest standards. 
                                        To guarantee that your project is constructed to last and matches your unique demands and preferences, we only utilise the greatest materials and cutting-edge processes.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="welcome-block-three">

                                    <div className="wt-box wt-product-gallery on-show-slider">
                                        <WelcomeSectionSlider />
                                        <WelcomeSectionSlider2 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






        </>
    )
}

export default WelcomeSection