import React, { useEffect } from 'react'
import './App.css';
import { BrowserRouter, HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import Home from './pages/home/Home';
import GridImages from './pages/GridImages';
import AboutUs from './pages/AboutUs';
import ContactUsPage from './pages/ContactUsPage';
import ServicesPage from './pages/ServicesPage';


const App = () => {
  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  return (
    <>

      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          <Route path='/' exact element={<MainLayout />}>
            <Route path='/' index element={<Home />} />
            <Route path="/grid-images" element={<GridImages />} />
            <Route path="/AboutUs" element={<AboutUs/>}/>
            <Route path="/contactus" element={<ContactUsPage/>}/>
            <Route path='/sevices-page' element={<ServicesPage/>}/>
          </Route>
        </Routes>
      </BrowserRouter>

      



    </>
  )
}

export default App