import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";


const WelcomeSectionSlider2 = () => {
    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={60}
            freeMode={true}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper"
            breakpoints={{
                360: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                },
                470: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                580: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
                1080: {
                    slidesPerView: 4,
                    spaceBetween: 4,
                },
            }}
        >


            <div id="sync2" className="owl-carousel owl-theme">
                <SwiperSlide>
                <div className="item">
                    <div className="wt-media">
                        <img src="assets/images/gallery/thumb/pic1.jpg" alt />
                    </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="item">
                    <div className="wt-media">
                        <img src="assets/images/gallery/thumb/pic2.jpg" alt />
                    </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="item">
                    <div className="wt-media">
                        <img src="assets/images/gallery/thumb/pic3.jpg" alt />
                    </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="item">
                    <div className="wt-media">
                        <img src="assets/images/gallery/thumb/pic4.jpg" alt />
                    </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="item">
                    <div className="wt-media">
                        <img src="assets/images/gallery/thumb/pic5.jpg" alt />
                    </div>
                </div>
                </SwiperSlide>
            </div>

        </Swiper>
    );
};

export default WelcomeSectionSlider2;