import React from 'react'
import HomeBannerSlider from '../../components/slider/HomeBannerSlider'

const Homebanner = () => {
    return (
        <>
            <div className='mt-4 border border-danger'>
                <HomeBannerSlider />
            </div>



        </>
    )
}

export default Homebanner