import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";


const HomeBannerSlider = () => {
    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={60}
            freeMode={true}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper"
            breakpoints={{
                360: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                },
                470: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                580: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
                1080: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
            }}
        >
            <div >
                <ul>
                    <SwiperSlide>
                        <div className="container1">
                            <img src="assets/images/main-slider/slider1/slide1.jpg" />
                            <div className="centered" >
                                    <h3 style={{color:'white'}}>INTERIOR DECORATORS IN HYDERABAD</h3>
                                    <p style={{color:'white'}}> With Innovative Ideas</p>
                                </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container1">
                            <img src="assets/images/main-slider/slider1/slide2.jpg" />
                            <div className="centered" >
                                    <h3 style={{color:'white'}}>INTERIOR DECORATORS IN HYDERABAD</h3>
                                    <p style={{color:'white'}}> With Innovative Ideas</p>
                                </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container1">
                            <img src="assets/images/main-slider/slider1/slide3.jpg" />
                            <div className="centered" >
                                    <h3>INTERIOR DECORATORS IN HYDERABAD</h3>
                                    <p> With Innovative Ideas</p>
                                </div>
                        </div>
                    </SwiperSlide>

                </ul>
                <div />
            </div>
        </Swiper>
    );
};

export default HomeBannerSlider;